// DATE - GENERAL: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
// DATE - FORMAT: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat

export default class TimestampSupport {
  constructor(){
    this.secondsInYear  = 31556952;
    this.secondsInMonth = 2629746;
    this.secondsInDay   = 86400;
    this.secondsInHour   = 3600;
    this.miliseconds    = 1000;
  }

  // CONVERTING
  integerToDate = (int) => new Date(int * this.miliseconds);
  asSeconds     = (dt) => Math.floor(dt / this.miliseconds);
  nowInSeconds  = () => this.asSeconds(Date.now());
  secondsToDays = (int) => Math.floor(int / this.secondsInDay);
  secondsToHours  = (int) => Math.floor(int / this.secondsInHour);

  // REFERENCE
  daysAgo(dt){
    const startSecs = this.asSeconds(new Date(dt));
    const diff = this.nowInSeconds() - startSecs;
    return this.secondsToDays(diff);
  }

  daysUntil(dt){
    const endingSecs = this.asSeconds(new Date(dt));
    const diff = endingSecs - this.nowInSeconds();
    return this.secondsToDays(diff);
  }

  // FORMATTING
  shortMdy(date, lang = 'en-US'){
    const d = new Date(date);
    const opts = {year: 'numeric', month: 'short', day: 'numeric'}
    const formatter = this.formatter(opts, lang);
    return formatter.format(d);
  }

  fullDatetime(date, lang = 'en-US'){
    const d = new Date(date);
    const opts = {dateStyle: 'full', timeStyle: 'long' };
    const formatter = this.formatter(opts, lang);
    return formatter.format(d);
  }

  formatter(opt, lang = 'en-US'){
    return Intl.DateTimeFormat(lang, opt);
  }

  // PHRASES
  beginsString(dt){
    const days = this.daysAgo(dt);
    if (days < 0){
      return "Begins in " + Math.abs(days) + " days.";
    } else if (days === 0) {
      return "Began yesterday.";
    } else {
      return "Began " + days + " days ago.";
    }
  }

  expiresString(dt){
    const days = this.daysUntil(dt);
    if (days === 1){
      return "Expires in " + days + " day.";
    } else {
      return "Expires in " + days + " days.";
    }
  }
}