import React, { Component } from "react";
import TimestampSupport from "../../helpers/timestamp-support";

export default class FocusEventCard extends Component {
  constructor(props){
    super(props)
    this.dt = new TimestampSupport();
  }

  isMidnightProx = () => this.props.proximity && this.props.proximity === "midnight";

  render(){
    let time = this.dt.integerToDate(this.props.focus.event);
    let midnightLabel = "Hours since midnight (local): " + this.dt.secondsToHours(this.props.focus.midnight) + " hrs";
    let newmoonLabel  = "Days since newmoon: " + this.dt.secondsToDays(this.props.focus.newmoon) + " days";
    let hibernalLabel = "Days since solstice: " + this.dt.secondsToDays(this.props.focus.hibernal) + " days";
    return(
      <div className="card mt-2" style={{width: "20rem"}}>
        <div className="card-body">
          <h5 className="card-title">{this.props.focus.title}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{this.dt.shortMdy(time)}</h6>
          <p className="card-text">{this.props.focus.title}</p>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
          {this.isMidnightProx() ? midnightLabel : newmoonLabel }
          </li>
          <li className="list-group-item">
          {this.isMidnightProx() ? newmoonLabel : hibernalLabel } 
          </li>
          {/* <li className="list-group-item">
            <a className="card-link" 
              target="_blank" 
              rel="noopener noreferrer">USGS Event Info Page</a>
          </li> */}
        </ul>
      </div>
    );
  }
}