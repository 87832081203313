import { Component } from "react";
import ContoursContainer from "../charts/contours-container";

export default class StudyPage extends Component {
  render(){
    return (
      <div className="row">
        <div className="col-sm-12">
          <h1>Studies</h1>
          <ContoursContainer selector={"dev-contours"} />
        </div>
      </div>
    )
  }
}