import React, { Component } from "react";
import {
  axisBottom,
  axisLeft,
  contourDensity,
  geoPath,
  select,
  max,
  min,
  quantile,
  scaleLinear
} from "d3";
import TimestampSupport from "../../helpers/timestamp-support";

// Example from https://observablehq.com/@d3/density-contours
export default class D3ContoursChart extends Component {
  constructor(props){
    super(props)
    this.dt = new TimestampSupport();
    this.selector = "#" + this.props.selector
  }

  componentDidMount(){
    this.destroyChart()
    this.drawChart();
  }
  
  componentDidUpdate(prevProps){
    if(prevProps.quantile !== this.props.quantile ||
      prevProps.focusEvent !== this.props.focusEvent){
      this.destroyChart()
      this.drawChart();
    }
  }

  colorForMag = (mag) => {
    var str;
    if(mag < 6.0){ str = "Tomato"}
    if(!str && mag < 7.0){str = "Crimson"}
    if(!str && mag < 8.0){str = "BlueViolet"}
    if(!str){str = "black"}
    return str
  }

  hoursTickLabel = (d) => this.dt.secondsToHours(d) + " hrs";
  daysTickLabel  = (d) => this.dt.secondsToDays(d) + " days";
  statsCallback  = (stats) => this.props.statsCallback(stats);
  clickedEvent   = (event, d) => this.props.clickedEventCallback(d);
    
  drawChart(){
    const data  = this.props.data;
    let mags    = data.map(d => d.usgs_mag);
    let bigMag  = quantile(mags, this.props.quantile); // what val is the limit per quantile
    const biggies = data.filter(d => d.usgs_mag > bigMag);
    this.statsCallback({
      contouredEvents: biggies.length,
      quantileMag: bigMag,
      maxMag: max(mags),
      minMag: min(mags),
    })
    let xMax = max(data.map(d => d.x))
    let yMax = max(data.map(d => d.y))
    
    // Dynamically get parent container width
    var container = select(this.selector).selectAll("svg");
    let viewWidth = container._parents[0].clientWidth;
    var margin = {top: 20, right: 20, bottom: 30, left: 50},
      width = viewWidth - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    // SET SCALES /////////
    const xScale = scaleLinear()
      .range([0, width])
      .domain([0, xMax]);

    const yScale = scaleLinear()
      .range([height, 0])
      .domain([0, yMax]);

    // SET AXIS & LABELS /////////
    const xAxisGenerator = axisBottom(xScale)
      .tickSizeOuter(0)
      .tickFormat((d,i) => {
        return (this.props.proximity && this.props.proximity === "midnight") ? this.hoursTickLabel(d) : this.daysTickLabel(d);
      });

    const yAxisGenerator = axisLeft(yScale)
      .tickSizeOuter(0)
      .tickFormat((d,i) => this.daysTickLabel(d));

    const xAxis = g => g.append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(xAxisGenerator)
      .call(g => g.select(".domain").remove())
      .call(g => g.select(".tick:last-of-type text").clone()
        .attr("y", -3)
        .attr("dy", null)
        .attr("font-weight", "bold")
        .text("Hours from midnight local time")
      );

    const yAxis = g => g.append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(yAxisGenerator)
      .call(g => g.select(".domain").remove())
      .call(g => g.select(".tick:last-of-type text").clone()
        .attr("x", 3)
        .attr("text-anchor", "start")
        .attr("font-weight", "bold")
        .text("Days from newmoon")
      );

    // PUT IT ALL TOGETHER /////////
    // append the svg obgect to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    const svg = select(this.selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const contours = contourDensity()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y))
      .size([width, height])
      .bandwidth(20)
      .thresholds(20)
      (biggies)

      svg.append("g").call(yAxis);

      svg.append("g").call(xAxis);
          
      svg.append("g")
        .attr("fill", "none")
        .attr("stroke", "CornflowerBlue")
        .attr("stroke-linejoin", "round")
          .selectAll("path")
          .data(contours)
          .join("path")
            .attr("stroke-width", (d, i) => i % 5 ? 0.25 : 1)
            .attr("d", geoPath());

      // Add the scatterplot of events
      svg.append("g")
      .selectAll("circle")
      .data(data)
      .join("circle")
        .style("cursor", "pointer")
        .attr("fill", d => {
          if(!this.props.focusEvent){
            return "white"
          } else {
            return parseInt(this.props.focusEvent.event) == d.event ? "lime" : "white";
          }
        })
        .attr("stroke", d => this.colorForMag(d.usgs_mag))
        .attr("stroke-width", "2")
        .attr("cx", d => xScale(d.x))
        .attr("cy", d => yScale(d.y))
        .attr("r", d => d.usgs_mag)
        .on("click", (e, d) => this.clickedEvent(e, d));
  }

  destroyChart(){
    select(this.selector).selectAll("svg").remove("svg")
  }

  render(){
    return(
      <div id={this.props.selector}></div>
    )
  }
}