import { Component } from "react";

export default class HomePage extends Component {
  render(){
    return (
      <>
      <div className="row">
        <div className="col-sm-12">
          <h1>{process.env.REACT_APP_NAME}</h1>
          <p>Welcome to the {process.env.REACT_APP_NAME} app, an investigation into the terrestrial landtide.</p>
        </div>
      </div>
      </>
    )
  }
}