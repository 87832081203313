import { Component } from "react";
import {csv} from 'd3'
import D3ContoursChart from "./d3-contours-chart";
import TimestampSupport from "../../helpers/timestamp-support";
import CardFocusEvent from "./card-focus-event";
import CardChartStats from "./card-chart-stats";
// import OrbitalClock from "../clock/orbital-clock";

export default class ContoursContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      loaded: false,
      slug: "california",
      quantile: 0.5,
      proximity: "midnight",
      focusEvent: null,
      data: null,
    }
    this.dt = new TimestampSupport();
  }

  componentDidMount(){
    this.loadData();
  }

  statsCallback = (stats) => this.setState({stats})
  
  clickedEvent          = (data) => this.setState({focusEvent: data})
  handleQuantileChange  = (event) => this.setState({quantile: parseFloat(event.target.value)});
  handleProximityChange = (event) => {
    this.setState(
      {
        proximity: event.target.value,
        data: null,
        stats: null,
        focusEvent: null
      },
      () => this.loadData()
      );
    }

  handleAreaChange = (event) => {
    this.setState(
      {
        slug: event.target.value, 
        data: null,
        stats: null,
        focusEvent: null
      }, 
      () => this.loadData()
    );
  }
  
  loadData(){
    csv("./data/" + this.state.slug + "/proximities.csv")
    .then(data => {
      // D3 CSV converts everything to string.
      // Format number values from strings to numbers
      //  and convert to days.
        if(this.state.proximity === "midnight"){
          data.forEach(d => {
            d.x        = +d.midnight;
            d.y        = +d.newmoon;
            d.usgs_mag = +d.usgs_mag;
          });
        } else {
          data.forEach(d => {
            d.x        = +d.hibernal;
            d.y        = +d.newmoon;
            d.usgs_mag = +d.usgs_mag;
          });
        }
        this.setState({data, loaded: true});
      });
  }

  render(){
    if(!this.state.loaded){ return null }
    return (
      <>
      <div className="row mb-2">
        <div className="col-md-4">
          <strong>Compare proximity:</strong>&nbsp;
          <br />
          <div className="form-check form-check-inline">
            <input className="form-check-input" 
              type="radio" 
              value="midnight"
              onChange={this.handleProximityChange}
              name="midnight" 
              checked={this.state.proximity === "midnight"} />
            <label className="form-check-label" 
              htmlFor="midnight">Midnight vs. Lunation</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" 
              type="radio" 
              value="hibernal"
              onChange={this.handleProximityChange}
              name="hibernal" 
              checked={this.state.proximity === "hibernal"} />
            <label className="form-check-label" 
              htmlFor="hibernal">Solstice vs. Lunation</label>
          </div>
          <br />

          <strong>Contour by magnitude:</strong>&nbsp;
          <div className="form-check form-check-inline">
            <input className="form-check-input" 
              type="radio" 
              value="0.75"
              onChange={this.handleQuantileChange}
              name="top-quantile" 
              checked={this.state.quantile === 0.75} />
            <label className="form-check-label" 
              htmlFor="top-quantile">Top 25%</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" 
              type="radio" 
              value="0.5"
              onChange={this.handleQuantileChange}
              name="middle-quantile" 
              checked={this.state.quantile === 0.5} />
            <label className="form-check-label" 
              htmlFor="middle-quantile">Top 50%</label>
          </div>

          <select className="form-select my-2" 
            value={this.state.slug} 
            onChange={this.handleAreaChange}>
            <option value="mexico">Mexico</option>
            <option value="california">California</option>
            <option value="honshu">Honshu (Japan)</option>
            <option value="polynesia">Polynesia</option>
          </select>
          
        </div>
        <div className="col-md-8"></div>
      </div>
      {this.state.data && <D3ContoursChart 
        data={this.state.data} 
        slug={this.state.slug}
        quantile={this.state.quantile}
        proximity={this.state.proximity}
        focusEvent={this.state.focusEvent}
        clickedEventCallback={this.clickedEvent}
        statsCallback={this.statsCallback}
        selector={this.props.selector} />}

      <div className="d-flex">
        {this.state.stats && <CardChartStats 
          slug={this.state.slug}
          {...this.state.stats} />}
        {this.state.focusEvent && <CardFocusEvent 
          proximity={this.state.proximity}
          focus={this.state.focusEvent} />
          }
      </div>
      </>
    )
  }
}