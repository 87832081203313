import { Component } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logos/red-solid.svg";

export default class LayoutsNavbar extends Component {
  render(){
    return (
      <div className="container-fluid">
        <nav className="navbar navbar-expand navbar-light">
          <NavLink to="/" 
            className={"navbar-brand"} >
              <span className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                <img src={Logo} alt="Landtide logo" width="40" height="40" />
              </span>
            </NavLink>
          <div className="navbar-collapse">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
              <NavLink to="/studies" className="nav-link">Studies</NavLink>
              </li>
              {/* <li className="nav-item">
              <NavLink to="/about" className="nav-link">About</NavLink>
              </li>
              <li className="nav-item">
              <NavLink to="/contact" className="nav-link">Contact</NavLink>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}