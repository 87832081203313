// https://reactrouter.com/docs/en/v6/upgrading/v5
// https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md
import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useLocation} from "react-router-dom";
import PageBody from '../components/layouts/page-body';
import HomePage from '../components/pages/home';
import StudyPage from '../components/pages/study';
// import AboutPage from '../components/pages/about';
// import ContactPage from '../components/pages/contact';

export default (
  <Router>
    <Routes>
      <Route path='/' element={
        <PageBody children={ <HomePage /> } />
      } />
      <Route path='/studies' element={
        <PageBody children={<StudyPage />} />
      } />
      {/* <Route path='/about' element={
        <PageBody children={ <AboutPage /> } />
      } />
      <Route path='/contact' element={
        <PageBody children={ <ContactPage /> } />
      } /> */}
      <Route path="*" element={
        <PageBody children={ <NoMatch /> } />
      } />
    </Routes>
  </Router>
);

function NoMatch() {
  let location = useLocation();
  return (
    <div className="p-4">
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
      <Link to="/">
        <span className="text-primary">Back Home</span>
      </Link>
    </div>
  );
}