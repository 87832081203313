import React, { Component } from "react";
import TimestampSupport from "../../helpers/timestamp-support";

export default class CardChartStats extends Component {
  constructor(props){
    super(props)
    this.dt = new TimestampSupport();
  }

  render(){
    let imgPath = "./img/globes/" + this.props.slug + ".png";
    return(
      <>
      <div className="card m-2" style={{width: "36rem"}}>
        <div className="row">
          <div className="col-sm-5">
            <img src={imgPath} 
              className="img-fluid rounded-start" 
              style={{width: "14rem", height: "14rem"}}
              alt="globe" />
          </div>
          <div className="col-sm-7">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
              Contoured events: {this.props.contouredEvents}
              </li>
              <li className="list-group-item">
              Quantile mag limit: {this.props.quantileMag}
              </li>
              <li className="list-group-item">
              Max mag: {this.props.maxMag} | Min mag: {this.props.minMag}
              </li>
              {/* <li className="list-group-item">
                <a className="card-link" 
                  target="_blank" 
                  rel="noopener noreferrer">USGS Event Info Page</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      </>
    );
  }
}