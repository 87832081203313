import { Component } from "react";
import LayoutsNavbar from "./navbar";

export default class PageBody extends Component {
  render(){
    return (
      <main className="container-fluid">
        <LayoutsNavbar />
        {this.props.children}
      </main>
    )
  }
}